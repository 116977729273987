<template>
  <div>
    <PoliciesAndTerms />
    <section id="home" class="video-hero" data-section="home">
      <div class="overlay top-bk"></div>
    </section>
    <div class="colorlib-work-featured colorlib-bg-white">
      <div class="container">
        <div v-if="!isSubmitted" class="row">
          <div
            class="col-md-12 col-md-offset-2 text-center colorlib-heading animate-box"
          >
            <h1>
              {{ $t("site.register.title") }}
              <i class="icon-profile-male"></i>
            </h1>
            <p>{{ $t("site.register.subtitle") }}</p>
          </div>
        </div>

        <div v-if="!isSubmitted" class="row d-flex justify-content-center">
          <div class="col-md-8 col-md-pull-4 animate-box">
            <form
              v-on:submit.prevent="sendNewUser"
              class="form-signin text-left"
            >
              <Loading
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="true"
              />
              <div class="card-plain_">
                <div class="row mt-4">
                  <div class="col-md-4 form-group">
                    <label for="language"
                      >{{ $tc("language.label", 1) }}:</label
                    >
                    <el-select
                      v-model="selectedLanguageId"
                      :placeholder="$tc('language.label', 1)"
                      name="language"
                      :required="true"
                      @change="changeLang"
                      class="w-100"
                    >
                      <el-option
                        :value="3"
                        :label="$t('language.english')"
                      ></el-option>
                      <el-option
                        :value="1"
                        :label="$t('language.portuguese')"
                      ></el-option>
                      <el-option
                        :value="2"
                        :label="$t('language.portuguese_br')"
                      ></el-option>
                    </el-select>
                  </div>
                  <div class="col-md-8 form-group">
                    <label for="name">{{ $tc("name", 1) }}:</label>
                    <input
                      v-model="newUser.name"
                      :placeholder="$tc('name', 1)"
                      name="name"
                      class="form-control input-md"
                      id="name"
                      type="text"
                    />
                  </div>
                  <div class="col-md-8 form-group">
                    <label for="company-name"
                      >{{ $tc("company_name", 1) }}:</label
                    >
                    <input
                      v-model="newUser.company_name"
                      name="company-name"
                      class="form-control input-md"
                      id="company-name"
                      type="text"
                      :placeholder="$tc('company_name', 1)"
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label for="currency"
                      >{{ $tc("currency.label", 1) }}:</label
                    >
                    <el-select
                      v-model="newUser.plan_currency_id"
                      filterable
                      :placeholder="$tc('currency.label', 1)"
                      name="currency"
                    >
                      <el-option
                        v-for="currency in currencies"
                        :key="currency.id"
                        :value="currency.id"
                        :label="$tc(`currency.${currency.code}`, 1)"
                      ></el-option>
                    </el-select>
                  </div>
                  <!-- <div class="col-md-6 form-group">
										<label for="ctry">{{ $tc('address.country', 1) }}:</label>
										<el-select
											v-model="newUser.country_id"
											filterable
											:placeholder="$tc('address.country', 1)"
											name="ctry"
											autocomplete="off"
										>
											<el-option
												v-for="country in countries"
												:key="country.id"
												:value="country.id"
												:label="country.name"
											></el-option>
										</el-select>
                  </div>-->
                  <!--  <div class="col-md-6 col-lg-6 form-group">
                    <label for="currency"
                      >{{ $t("operating_area.label") }}:</label
                    >

                    <el-select
                      v-model="newUser.operating_area_id"
                      :placeholder="$tc('operating_area.label', 1)"
                      name="operating_area"
                      class="w-100"
                      filterable
                    >
                      <el-option
                        :value="8"
                        :label="$t('operating_area.culture')"
                      ></el-option>
                      <el-option
                        :value="9"
                        :label="$t('operating_area.education')"
                      ></el-option>
                      <el-option
                        :value="6"
                        :label="$t('operating_area.financial')"
                      ></el-option>
                      <el-option
                        :value="10"
                        :label="$t('operating_area.health')"
                      ></el-option>
                      <el-option
                        :value="2"
                        :label="$t('operating_area.human_resources')"
                      ></el-option>
                      <el-option
                        :value="7"
                        :label="$t('operating_area.marketing')"
                      ></el-option>
                      <el-option
                        :value="3"
                        :label="$t('operating_area.provide_services')"
                      ></el-option>
                      <el-option
                        :value="4"
                        :label="$t('operating_area.product_sales')"
                      ></el-option>
                      <el-option
                        :value="5"
                        :label="$t('operating_area.products_and_services')"
                      ></el-option>
                      <el-option
                        :value="6"
                        :label="$t('operating_area.ti')"
                      ></el-option>
                      <el-option
                        :value="1"
                        :label="$t('operating_area.others')"
                      ></el-option>
                    </el-select>
                  </div>
                  <div v-if="selectedPlan && selectedPlan.name" class="col-md-6 col-lg-6 form-group">
										<label>{{ $tc('plan.label', 1) }}:</label>
										<router-link
											:to="{ name: 'plans', params: { plan_id: selectedPlan.id }}"
											class="font-weight-bold"
										>
											{{ selectedPlan.name }}
										</router-link>
                  </div>-->

                  <div class="col-md-6 form-group">
                    <label for="email">{{ $tc("email") }}:</label>
                    <input
                      v-model="newUser.email"
                      name="email"
                      class="form-control input-md"
                      id="email"
                      type="email"
                      :placeholder="$tc('email')"
                    />
                  </div>
                  <div class="row_">
                    <div class="col-md-12 col-lg-12 form-group">
                      <label for="password">{{ $tc("password") }}:</label>
                      <fg-input
                        v-model="newUser.password"
                        :placeholder="$tc('password')"
                        name="password"
                        id="password"
                        type="password"
                        autocomplete="true"
                      ></fg-input>
                    </div>
                    <div class="col-md-12 col-lg-12 form-group">
                      <fg-input
                        v-model="newUser.password_confirmation"
                        :label="$tc('password_repeat')"
                        name="reapeat-password"
                        id="reapeat-password"
                        autocomplete="true"
                        type="password"
                      ></fg-input>
                    </div>
                  </div>

                  <!-- <div class="col-md-12 form-group mt-3">
                    <CheckBox v-model="newUser.referral_status" />
                    {{ $tc('referral.label', 1) }} 
                    <small> 
                      ({{ $tc('referral.text_register', 1) }})
                    </small>

                    <div v-if="newUser.referral_status">
                      <input
                        v-model="newUser.referral"
                        name="email"
                        class="form-control input-md"
                        id="email"
                        type="email"
                        :placeholder="$tc('email')"
                      />
                    </div>
                  </div> -->

                  <div class="col-md-12 form-group">
                    <CheckBox v-model="newUser.accept_terms_use" label />
                    <a @click.prevent="setShowTermsUse(true)" href="#">{{
                      $t("site.register.registered.accept_terms")
                    }}</a>
                  </div>
                  <div class="col-md-12 form-group">
                    <CheckBox v-model="newUser.accept_privacy_policy" label />
                    <a @click.prevent="setShowPrivacyPolicy(true)" href="#">{{
                      $t("site.register.registered.accept_privacy_policy")
                    }}</a>
                  </div>

                  <div class="col-md-12">
                    <ErrorsArea :errors-data="formErrors" />
                  </div>
                  <div class="col-md-12">
                    <hr />
                    <button
                      type="sumbmit"
                      class="btn btn-success mt-2 col-md-4"
                    >
                      <i class="nc-icon nc-check-2"></i>
                      {{ $t("action.register") }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- <div v-else>
                <h3 class="text-center">
                  <p class="h3 text-center">{{ $t('site.register.registered.title') }}</p>
                  <p class="h4">{{ $t('site.register.registered.msn', { email: emailSent }) }}</p>
                  <p class="h4">{{ $t('site.register.registered.check_email') }}</p>
                  <div class="icon-msn text-center">
                    <i class="nc-icon nc-email-83"></i>
                     <MessageSent/> 
                  </div>
                </h3>
              </div> -->
            </form>
          </div>
        </div>

        <div v-else>
          <h1 class="text-center">
            {{ this.$t("site.register.registered.title") }}
          </h1>
          <p
            class="text-center"
            v-html="
              this.$t('site.register.registered.msn', { email: this.emailSent })
            "
          ></p>
          <p class="text-center">
            {{ this.$t("site.register.registered.check_email") }}
          </p>
          <div class="text-center check-icon text-center text-success">
            <i class="icon-check"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import ErrorsArea from "@/components/ErrorsArea/";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Option, Select } from "element-ui";
import PoliciesAndTerms from "@/components/PoliciesAndTerms/";

export default {
  name: "ContactPage",
  components: {
    ErrorsArea,
    Loading,
    ElOption: Option,
    ElSelect: Select,
    PoliciesAndTerms,
  },
  data() {
    return {
      formErrors: {},
      isLoading: false,
      showTermsUse: false,
      emailSent: "",
      accountCreated: false,
      isSubmitted: false,
      langs: [
        { id: 3, code: "en", label: "EN", img: "en.svg" },
        { id: 1, code: "pt", label: "PT", img: "pt.svg" },
        { id: 2, code: "pt_br", label: "PT-BR", img: "pt-br.svg" },
      ],
    };
  },
  computed: {
    ...mapState("utils", [
      "plans",
      "currencies",
      "selectedPlanId",
      "selectedCurrencyId",
      "countries",
    ]),
    ...mapState("user", ["newUserRegister", "userLocate"]),
    ...mapGetters("utils", ["selectedPlan"]),
    languages() {
      return this.$store.state.utils.languages;
    },
    selectedLang() {
      return this.languages.find((lang) => {
        return lang.id === this.selectedLanguageId;
      });
    },
    selectedLanguageId: {
      get() {
        return this.$store.state.utils.selectedLanguageId;
      },
      set(newValue) {
        this.setSelectedLanguageId(newValue);

        this.$i18n.locale = this.selectedLang.code;
        this.selectedLangCode = this.selectedLang.code;
        localStorage.LangCode = this.selectedLang.code;
      },
    },
    currencyId: {
      get() {
        return this.selectedCurrencyId;
      },
      set(newValue) {
        this.setSelectedCurrencies(newValue);
      },
    },
    newUser: {
      get() {
        return this.newUserRegister;
      },
      set(newValue) {
        this.setNewUserRegister(newValue);
      },
    },
  },
  mounted() {
    this.$helpers.ga.openPage({
      page: "create_account",
      title: "Create Account",
    });
    this.$helpers.scrollTop(0);
    this.accountCreated = false;
  },
  beforeDestroy() {
    this.accountCreated = false;
  },
  methods: {
    ...mapMutations("utils", [
      "setShowTermsUse",
      "setShowPrivacyPolicy",
      "setSelectedCurrencies",
      "setNewUserRegister",
      "setSelectedLanguageId",
    ]),
    ...mapActions("User", ["loginUser", "getUserLocate"]),
    sendNewUser() {
      this.$helpers.ga.event({
        event: "submit_form",
        label: "click_btn_register",
        value: this.selectedPlanId,
      });
      this.formErrors = {};
      this.isLoading = true;
      this.newUser.country_id = 1;
      this.newUser.plan_id = this.selectedPlanId;
      this.newUser.hash = this.$route.params.token;
      this.newUser.is_external = this.isExternal;
      this.newUser.profile_id = this.profileId;
      this.newUser.account_id = this.originalAccountId;
      this.newUser.language_id = this.selectedLanguageId;
      this.newUser.referral = window.localStorage.getItem('ref') ? window.localStorage.getItem('ref') : null;
      this.emailSent = this.newUser.email;

      // this.newUser.plan_currency_id = this.selectedCurrencyId;
      window.$axios
        .post("api/user/create", this.newUser)
        .then((response) => {
          this.posts = response.data;
          this.isLoading = false;

          this.clearForm();
          this.$helpers.scrollTop(0);
          this.capterraCall();
          this.accountCreated = true;
          this.isSubmitted = true;
        })
        .catch((data) => {
          this.isLoading = false;
          if (data.response && data.response.data) {
            this.formErrors = data.response.data;
          }
          // this.$notify({
          //   message: this.$t("error.generic", {code: 115}),
          //   type: "danger"
          // });
        });
    },
    capterraCall() {
      (function() {
        console.log("Capterra call");
        var capterra_vkey = "7461743738c98fdf7530e688caaa2c2b",
          capterra_vid = "2146680",
          capterra_prefix =
            "https:" == document.location.protocol
              ? "https://ct.capterra.com"
              : "http://ct.capterra.com";
        var ct = document.createElement("script");
        ct.type = "text/javascript";
        ct.async = true;
        ct.src =
          capterra_prefix +
          "/capterra_tracker.js?vid=" +
          capterra_vid +
          "&vkey=" +
          capterra_vkey;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(ct, s);
      })();
    },
    setLanguageId(languageId = null) {
      let languageCode;

      this.$helpers.ga.event({
        event: "click_btn",
        label: "set_language",
        value: this.selectedPlanId,
      });

      if (languageId) {
        switch (languageId) {
          case 1:
            languageCode = "pt";
            break;
          case 2:
            languageCode = "pt_br";
            break;
          case 3:
            languageCode = "en";
            break;
        }
      } else if (!languageId && localStorage.LangCode) {
        languageCode = localStorage.LangCode;
      } else if (!languageId && !localStorage.LangCode) {
        this.setLanguageId(3);
      }

      if (languageCode) {
        switch (localStorage.LangCode) {
          case "en":
            this.newUser.language_id = 3;
            break;
          case "pt":
            this.newUser.language_id = 1;
            break;
          case "pt_br":
            this.newUser.language_id = 2;
            break;
        }

        this.$i18n.locale = languageCode;
        this.selectedLangCode = languageCode;
        localStorage.LangCode = languageCode;
      }
    },
    changeLang() {
      // const selectedLang = this.langs.find(lang => {
      //   return lang.id === this.newUser.language_id;
      // });
      // this.$i18n.locale = selectedLang.code;
      // this.selectedLangCode = selectedLang.code;
      // localStorage.LangCode = selectedLang.code;
    },
    clearForm() {
      this.formErrors = {};
      this.isLoading = false;
      this.newUser.name = "";
      this.newUser.company_name = "";
      this.newUser.language_id = 1;
      this.newUser.email = "";
      this.newUser.password = "";
      this.newUser.password_confirmation = "";
    },
  },
};
</script>

<style scoped>
.check-icon {
  font-size: 3rem;
}
</style>
