<template>
<div>
  <el-dialog
    :title="$tc('message.terms_of_use', 2)"
    :visible.sync="showModalTermsUse"
  >
    <span>
      <TermsUse/>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showModalTermsUse = false">{{ $t('action.close') }}</el-button>
    </span>
  </el-dialog>

  <el-dialog
    title="Privacy Policy"
    :visible.sync="showModalPrivacyPolicy"
  >
    <span>
      <PrivacyPolicy/>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showModalPrivacyPolicy = false">{{ $t('action.close') }}</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import PrivacyPolicy from './PrivacyPolicy';
import TermsUse from './TermsUse';

export default {
  name: 'PoliciesAndTerms',
  components: {
    PrivacyPolicy,
    TermsUse
  },
  computed: {
    ...mapState('utils',
    [
      'showTermsUse',
      'showPrivacyPolicy'
    ]),
    showModalTermsUse: {
      get() {
        return this.showTermsUse;
      },
      set(value) {
        this.setShowTermsUse(value);
      }
    },
    showModalPrivacyPolicy: {
      get() {
        return this.showPrivacyPolicy;
      },
      set(value) {
        this.setShowPrivacyPolicy(value);
      }
    }
  },
  methods: {
    ...mapMutations('utils', [
      'setShowTermsUse',
      'setShowPrivacyPolicy'
    ]),
  }
}
</script>
